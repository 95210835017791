import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { TreeSelect } from 'primereact/treeselect'
import React from 'react'
import XLSX from 'xlsx'
import List from '../../classes/List'
import DateInput from '../../components/inputs/DateInput'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { getHistoricoMovimentacaoDTO } from '../../dtos/zeroKm/HistoricoMovimentacaoDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatDateTime, formatPlaca } from '../../helpers/formaters'
import { getStore } from '../../redux/store'
import UsuarioService from '../../services/cadastro/UsuarioService'
import HistoricoMovimentacaoService from '../../services/zeroKm/historico/HistoricoMovimentacaoService'

class HistoricoMovimentacao extends List {
  constructor (props) {
    super(
      props,
      getHistoricoMovimentacaoDTO,
      HistoricoMovimentacaoService,
      '',
      '',
      't'
    )

    this.state = {
      ...this.state,
      dataMovimentacaoInicial: null,
      dataMovimentacaoFinal: null,
      operacao: null,
      cuscreCheckBox: false,
      companies: [],
      operacaoOptions: [],
      moduloOptions: [],
      selectedCompanies: {
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      },
      selectedOperacoes: { },
      selectedModlulos: {
        0: { checked: true, partialChecked: true },
        '0-0': { checked: true, partialChecked: false },
        '0-1': { checked: true, partialChecked: false },
        '0-2': { checked: true, partialChecked: false },
        '0-3': { checked: true, partialChecked: false }
      }

    }

    //#region Criando colunas.

    this.setColumns([
      {
        key: 'nomfan_emp',
        component: (
          <Column
            key='nomfan_emp'
            style={{ width: '20%' }}
            field="nomfan_emp"
            header="Empresa"
            sortable
            headerClassName="nomfan_emp" />
        )
      },
      {
        key: 'chassi_mov',
        component: (
          <Column
            key='chassi_mov'
            style={{ width: '12%' }}
            header="Placa/Chassi"
            body={data => data.placa_mov ? formatPlaca(data.placa_mov) : data.chassi_mov}
            sortable
            headerClassName="chassi_mov" />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            key='descri_vei'
            style={{ width: '17%' }}
            field="descri_vei"
            header="Marca/Modelo"
            sortable
            headerClassName="descri_vei" />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            key='cor_vei'
            style={{ width: '10%' }}
            field="cor_vei"
            header="Cor"
            sortable
            headerClassName="cor_vei" />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            key='nome_usu'
            style={{ width: '15%' }}
            field="nome_usu"
            header="Usuario"
            sortable
            headerClassName="nome_usu" />
        )
      },
      {
        key: 'descri_ope',
        component: (
          <Column
            key='descri_ope'
            style={{ width: '15%' }}
            field="descri_ope"
            header="Operação"
            sortable
            headerClassName="descri_ope" />
        )
      },
      {
        key: 'dathor_mov',
        component: (
          <Column
            key='dathor_mov'
            style={{ width: '10%' }}
            field="dathor_mov"
            header="Movimentação"
            body={data => data.dathor_mov ? formatDateTime(data.dathor_mov) : ''}
            sortable
            headerClassName="dathor_mov" />
        )
      }
    ])

    //#endregion
  }

  async componentDidMount () {
    await super.componentDidMount()
    const getOperacoes = await HistoricoMovimentacaoService.getOperacoes()
    const getModulos = await HistoricoMovimentacaoService.getModulos()
    const empresasAcesso = await UsuarioService.empresasAcessoUsuarioLogado()
    const empresasAcessoAtivas = empresasAcesso.filter((empresa) => empresa.ativo_emp)

    //Colocando a empresa logada como primeiro registro do array
    const empresaLogada = empresasAcessoAtivas.filter((empresa) => empresa.codigo_emp === getStore().empresaLogada.codigo_emp).shift()
    empresasAcessoAtivas.splice(empresasAcessoAtivas.indexOf(empresaLogada), 1)
    empresasAcessoAtivas.unshift(empresaLogada)
    const empresas = empresasAcessoAtivas.map((empresa, idx) => {
      return {
        key: `0-${idx}`,
        label: empresa?.nomfan_emp,
        data: empresa?.codigo_emp
      }
    })
    const operacoes = getOperacoes.map((operacao, idx) => {
      return {
        key: `0-${idx}`,
        label: operacao?.descri_ope,
        data: operacao?.codigo_ope
      }
    })

    const modulos = getModulos.map((modulo, idx) => {
      return {
        key: `0-${idx}`,
        label: modulo?.descri_mod,
        data: modulo?.codigo_mod
      }
    })

    const companies = [{
      key: 0,
      label: 'Empresas',
      children: [...empresas]
    }]

    const operacaoOptions = [{
      key: 0,
      label: 'Operações',
      children: [...operacoes]
    }]

    const moduloOptions = [{
      key: 0,
      label: 'Módulos',
      children: [...modulos]
    }]

    this.setState({ operacaoOptions, companies, moduloOptions })
  }

  handleExportarExcel = async () => {
    const historicoMovimentacaoService = new HistoricoMovimentacaoService()
    const response = await historicoMovimentacaoService.filter(this.toFilter())

    const historicoMovimentacao = []
    for (const registro of response.data) {
      const row = {
        Empresa: registro.nomfan_emp,
        Chassi: registro.chassi_mov,
        'Descricao do veículo': registro.descri_vei,
        Cor: registro.cor_vei,
        'Nome do usuário': registro.nome_usu,
        'Operação Realizada': registro.descri_ope,
        'Data da movimentação': formatDateTime(registro.dathor_mov)
      }
      historicoMovimentacao.push(row)
    }

    const workSheet = XLSX.utils.json_to_sheet(historicoMovimentacao)

    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }

    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
    const now = new Date()
    const formattedDateTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}`

    XLSX.writeFile(workBook, `Historico-Movimentacao-${formattedDateTime}.xlsx`)
  }

    aplicaVariaveisNoFilter = () => {
      const { filter, selectedCompanies, companies, selectedOperacoes, operacaoOptions, selectedModlulos, moduloOptions } = this.state
      filter.dataMovimentacaoInicial = CommonHelper.dateToAmerican(this.state.dataMovimentacaoInicial)
      filter.dataMovimentacaoFinal = CommonHelper.dateToAmerican(this.state.dataMovimentacaoFinal)

      if (this.state.cuscreCheckBox) {
        filter.mostraApenasCuscre = true
        filter.codope = null
      } else {
        filter.codope = this.state.operacao ? this.state.operacao.codigo_ope : null
        filter.mostraApenasCuscre = null
      }

      filter.idsEmpresas = CommonHelper.convertObjectToList(selectedCompanies, companies)
      filter.idsOperacoes = CommonHelper.convertObjectToList(selectedOperacoes, operacaoOptions)
      filter.idsModulos = CommonHelper.convertObjectToList(selectedModlulos, moduloOptions)

      this.setState({ filter })
    }

  onFilterClick = () => {
    this.aplicaVariaveisNoFilter()
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-6">
        <label className="label">Data da movimentação</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoInicial}
              onChange={(e) => this.setState({ dataMovimentacaoInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoFinal}
              onChange={(e) => this.setState({ dataMovimentacaoFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Empresas</label>
        <TreeSelect
          value={this.state.selectedCompanies}
          options={this.state.companies}
          onChange={(e) => this.setState({ selectedCompanies: e.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione uma empresa"
          filter
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Operações</label>
        <TreeSelect
          value={this.state.selectedOperacoes}
          options={this.state.operacaoOptions}
          onChange={(e) => this.setState({ selectedOperacoes: e.target.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione uma operação"
          filter
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Módulos</label>
        <TreeSelect
          value={this.state.selectedModlulos}
          options={this.state.moduloOptions}
          onChange={(e) => this.setState({ selectedModlulos: e.target.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione um módulo"
          filter
        />
      </div>
      <div className="field checkbox-container col-12 md:col-4 margin-top">
        <Checkbox
          value={this.state.cuscreCheckBox}
          onChange={() => this.setState({ cuscreCheckBox: !this.state.cuscreCheckBox })}
          checked={this.state.cuscreCheckBox}
        />
        <label className="p-checkbox-label mb-0 pl-2">Apenas operações que geram cobrança.</label>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Histórico de movimentação</h4>
          <div className="page-header-buttons">
            <Button
              label="Exportar em excel"
              onClick={() => this.handleExportarExcel()}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            loading={state.loading}
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Procurar histórico de movimentação"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

export default HistoricoMovimentacao
