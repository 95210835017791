import { Checkbox } from 'primereact/checkbox'
import { Fieldset } from 'primereact/fieldset'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import React, { useState } from 'react'
import DateInput from '../../components/inputs/DateInput'
import SancesInputCFPCNPJmask from '../../components/inputs/MaskedInputs/CPFCNPJ'
import SearchInput from '../../components/inputs/SearchInput'
import TextInput from '../../components/inputs/TextInput'
import FieldErrorMessage from '../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../components/utils/Message'
import RequiredLabel from '../../components/utils/RequiredLabel'
import { getEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import { getParceiroDTO } from '../../dtos/cadastro/ParceiroDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import { useValidateInput } from '../../helpers/useValidateInput'
import EmpresaService from '../../services/cadastro/EmpresaService'
import ParceiroService from '../../services/cadastro/ParceiroService'

// * Componente principal
const DadosPrincInfoFinanceira = ({
  form,
  edicaoHabilitada,
  empFaturamentoSelecionada,
  setEmpFaturamentoSelecionada,
  parceiroSelecionado,
  setParceiroSelecionado
}) => {
  // * controle dos campos do form e verifica se empresa é despachante
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const isEmpDesp = CommonHelper.isEmpresaDespachante()

  // * estado e função para controle de sugestão ao escreve no selecionar empresa de faturamento
  const [sugestaoEmpFaturamento, setSugestaoEmpFaturamento] = useState([])
  async function sugerirEmpFaturamento (event) {
    try {
      const empresaService = new EmpresaService()
      const res = await empresaService.filter(
        `descricaoFiltro=${event.query}`
      )
      if (res?.data?.length <= 0) {
        showWarnMessage('Empresa de faturamento não encontrada!')
      }

      if (res?.data?.length > 0) {
        setSugestaoEmpFaturamento(res?.data)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma empresa de faturamento!')
    }
  }

  // * Seta string ou objeto
  function handleChangeOrSelectEmpFaturamento (e) {
    // * valor em string para função sugerirEmpFaturamento
    if (typeof e.value === 'string') {
      setEmpFaturamentoSelecionada(e.value)
      return
    }

    // * Confere se o objeto ja existe no data
    // * Seta objeto ja existente ou altera para novo
    setEmpFaturamentoSelecionada(e.value)
    if (!e.value.codigo_emp) {
      setEmpFaturamentoSelecionada(null)
    }
  }

  // * estado e função para controle de sugestão ao escreve no selecionar Parceiro
  const [sugestaoParceiro, setSugestaoParceiro] = useState([])
  async function sugerirParceiro (event) {
    try {
      const parceiroService = new ParceiroService()
      const res = await parceiroService.filter(
        `descricaoFiltro=${event.query}`
      )
      if (res?.data?.length <= 0) {
        showWarnMessage('Parceiro não encontrado!')
      }

      if (res?.data?.length > 0) {
        setSugestaoParceiro(res?.data)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um parceiro!')
    }
  }

  // * Seta string ou objeto
  function handleChangeOrSelectParceiro (e) {
    // * valor em string para função sugerirParceiro
    if (typeof e.value === 'string') {
      setParceiroSelecionado(e.value)
      return
    }

    // * Confere se o objeto ja existe no data
    // * Seta objeto ja existente ou altera para novo
    setParceiroSelecionado(e.value)
    if (!e.value.codigo_pcr) {
      setParceiroSelecionado(null)
    }
  }

  return (
    <div className="formgrid grid">

      {/* FIRST LINE */}

      <div className="field col-12 md:col-2 md:mb-5">
        <RequiredLabel label={!isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'} valid={isFormFieldValid('cnpjEmp')}/>
        <SancesInputCFPCNPJmask
          name="cnpjEmp"
          value={form.values.cnpjEmp}
          formFieldValid={'cnpjEmp'}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
          isFormFieldValid={isFormFieldValid('cnpjEmp')}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp') }, 'p-inputtext p-component p-filled inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpjEmp')}/>
      </div>
      <div className="field col-12 md:col-5 md:mb-5">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomrazEmp')}/>
        <TextInput
          name="nomrazEmp"
          placeholder="Nome/Razão Social"
          value={form.values.nomrazEmp}
          className={classNames({ 'p-invalid': isFormFieldValid('nomrazEmp') }, 'inputfield w-full')}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomrazEmp')}/>
      </div>
      <div className="field col-12 md:col-5 md:mb-5">
        <RequiredLabel label="Nome fantasia" valid={isFormFieldValid('nomfanEmp')}/>
        <TextInput
          name="nomfanEmp"
          placeholder="Nome fantasia"
          value={form.values.nomfanEmp}
          className={classNames({ 'p-invalid': isFormFieldValid('nomfanEmp') }, 'inputfield w-full')}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomfanEmp')}/>
      </div>

      {/* NEW LINE */}

      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Mínimo de transação (Novos)" valid={isFormFieldValid('qtdminnovCte')}/>
        <TextInput
          name="qtdminnovCte"
          placeholder="Mínimo de transação (Novos)"
          maxLength={10}
          className={classNames({ 'p-invalid': isFormFieldValid('qtdminnovCte') }, 'inputfield w-full')}
          value={form.values.qtdminnovCte}
          onChange={(e) =>
            Number(e.target.value) >= 0 ? form.handleChange(e) : undefined
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('qtdminnovCte')}/>
      </div>
      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Mínimo de transação (Usados)" valid={isFormFieldValid('qtdminusaCte')}/>
        <TextInput
          placeholder="Mínimo de transação (Usados)"
          name="qtdminusaCte"
          maxLength={10}
          className={classNames({ 'p-invalid': isFormFieldValid('qtdminusaCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={form.values.qtdminusaCte}
          onChange={(e) =>
            Number(e.target.value) >= 0 ? form.handleChange(e) : undefined
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('qtdminusaCte')}/>
      </div>
      <div className="field checkbox-container md:col-2 md:mb-5 col-4">
        <Checkbox
          name="ativoEmp"
          value={form.values.ativoEmp}
          onChange={form.handleChange}
          checked={form.values.ativoEmp}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-3">Ativo</label>
      </div>
      <div className="field md:col-4 md:mb-5 col-8">
        <DateInput
          label="Data de desativação"
          placeholder="Data de desativação"
          name="datdesEmp"
          value={form.values.datdesEmp}
          disabled={'disabled'}
          className="inputfield w-full"
        />
      </div>

      {/* NEW LINE */}

      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Custo de transação (Novos)" valid={isFormFieldValid('custranovCte')}/>
        <TextInput
          placeholder="Custo de transação (Novos)"
          name="custranovCte"
          className={classNames({ 'p-invalid': isFormFieldValid('custranovCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={formatToCurrency(form.values.custranovCte)}
          onChange={(e) => {
            e.target.value = formatCurrencyToDecimal(e.target.value)
            if (Number(e.target.value) >= 0) return form.handleChange(e)
          }
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('custranovCte')}/>
      </div>
      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Custo de transação (Usados)" valid={isFormFieldValid('custrausaCte')}/>
        <TextInput
          placeholder="Custo de transação (Usados)"
          name="custrausaCte"
          className={classNames({ 'p-invalid': isFormFieldValid('custrausaCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={formatToCurrency(form.values.custrausaCte)}
          onChange={(e) => {
            e.target.value = formatCurrencyToDecimal(e.target.value)
            if (Number(e.target.value) >= 0) return form.handleChange(e)
          }
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('custrausaCte')}/>
      </div>
      <div className="field checkbox-container md:col-2 md:mb-5 col-4">
        <Checkbox
          name="bloqfinEmp"
          value={form.values.bloqfinEmp}
          onChange={form.handleChange}
          checked={form.values.bloqfinEmp}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-3">Bloqueio Financeiro</label>
      </div>
      <div className="field md:col-4 md:mb-5 col-8">
        <DateInput
          label="Data de bloqueio"
          placeholder="Data de bloqueio"
          name="dahbloqfinEmp"
          value={form.values.dahbloqfinEmp}
          disabled={'disabled'}
          className="inputfield w-full"
        />
      </div>

      {/* NEW LINE */}

      <div className='col-12 md:col-6'>
        <div className="col-12 md:flex">
          <div className="field checkbox-container col-12 md:col-6 md:mb-3 custom-target-tooltip">
            <Tooltip target=".custom-target-tooltip" />
            <Checkbox
              name="empfatautEmp"
              value={form.values.empfatautEmp}
              onChange={(e) => {
                if (!empFaturamentoSelecionada.nomfan_emp && !e.target.value) {
                  showErrorMessage('Selecione uma empresa de faturamente antes de ativar esta opção')
                  return
                }
                form.handleChange(e)
              }}
              checked={form.values.empfatautEmp}
              disabled={!edicaoHabilitada}
              tooltipOptions={{ position: 'top' }}
              tooltip='Ao desabilitar essa opção, pode ser alterado a empresa de faturamento!'
              className='custom-target-tooltip'
            />
            <label className="p-checkbox-label mb-0 pl-3">Definir empresa de faturamento automaticamente?</label>
          </div>
          <div className="field col-12 md:col-6 p-0">
            <SearchInput
              // * props
              name='empfan_emp'
              field='nomfan_emp'
              className="inputfield w-full"
              label="Empresa de Faturamento"
              placeholder="Empresa de Faturamento"
              disabled={!edicaoHabilitada || !!form.values.empfatautEmp}

              // * Controle do valor e seleção da empresa de faturamento
              value={typeof empFaturamentoSelecionada === 'string' ? empFaturamentoSelecionada : empFaturamentoSelecionada?.nomfan_emp}
              onSelect={handleChangeOrSelectEmpFaturamento}

              // * Controle de sugestão do autocomplete
              suggestions={sugestaoEmpFaturamento}
              completeMethod={sugerirEmpFaturamento}
              onChange={handleChangeOrSelectEmpFaturamento}

              // * controle do FiltroModal
              filtroTitle="Pesquisa de empresas de faturamento"
              service={EmpresaService}
              model={getEmpresaDTO}
              primaryKeyName='codigo_emp'
              columns={[
                { campo: 'codigo_emp', nome: 'Código' },
                { campo: 'nomfan_emp', nome: 'Nome' },
                { campo: 'cnpj_emp', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
              ]}
            />
          </div>
        </div>

        {/* NEW LINE */}

        <div className="field col-12">
          <Fieldset
            legend="Responsável pelo financeiro"
            className='custom-fieldset'
          >
            <div className="field col-12 ">
              <TextInput
                value={form.values.nomresfinEmp}
                name="nomresfinEmp"
                placeholder="Nome"
                label="Nome"
                className="inputfield w-full"
                onChange={form.handleChange}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
              />
            </div>
            <div className="field col-12 md:col-7">
              <TextInput
                value={form.values.emaresfinEmp}
                name="emaresfinEmp"
                placeholder="Email"
                label="Email"
                className="inputfield w-full"
                onChange={form.handleChange}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
              />
            </div>
            <div className="field col-12 md:col-5">
              <TextInput
                mask="(99) 99999-999?9"
                value={form.values.telresfinEmp}
                name="telresfinEmp"
                placeholder="Telefone"
                label="Telefone"
                className="inputfield w-full"
                onChange={form.handleChange}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
              />
            </div>
          </Fieldset>
        </div>

      </div>

      {/* DIVIDER */}

      <div className='col-12 md:col-6'>
        <div className="field col-12 md:col-12 p-0">
          <SearchInput
            // * props
            name='codpcr_emp'
            field='nomraz_pcr'
            className="inputfield w-full"
            label="Parceiro"
            placeholder="Parceiro"
            disabled={!edicaoHabilitada}

            // * Controle do valor e seleção da empresa de faturamento
            value={typeof parceiroSelecionado === 'string' ? parceiroSelecionado : parceiroSelecionado?.nomraz_pcr}
            onSelect={handleChangeOrSelectParceiro}

            // * Controle de sugestão do autocomplete
            suggestions={sugestaoParceiro}
            completeMethod={sugerirParceiro}
            onChange={handleChangeOrSelectParceiro}

            // * controle do FiltroModal
            filtroTitle="Pesquisa de parceiros"
            service={ParceiroService}
            model={getParceiroDTO}
            primaryKeyName='codigo_pcr'
            columns={[
              { campo: 'codigo_pcr', nome: 'Código' },
              { campo: 'nomraz_pcr', nome: 'Nome' },
              { campo: 'cnpj_pcr', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>

        <div className="field col-12">
          <Fieldset
            legend="Valor de repasse para o parceiro"
            className='custom-fieldset'
          >
            <div className="field col-12 md:col-6">
              <TextInput
                name="valpcrusaEmp"
                label="Valor parceria usados"
                placeholder="Valor parceria usados"
                className={'inputfield w-full'}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
                value={formatToCurrency(form.values.valpcrusaEmp)}
                onChange={(e) => {
                  e.target.value = formatCurrencyToDecimal(e.target.value)
                  form.handleChange(e)
                }}
              />
            </div>
            <div className="field col-12 md:col-6">
              <TextInput
                name="valpcrnovEmp"
                label="Valor parceria novos"
                placeholder="Valor parceria novos"
                className={'inputfield w-full'}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
                value={formatToCurrency(form.values.valpcrnovEmp)}
                onChange={(e) => {
                  e.target.value = formatCurrencyToDecimal(e.target.value)
                  form.handleChange(e)
                }}
              />
            </div>
            <div className="field col-12 md:col-6">
              <TextInput
                name="valpcriteEmp"
                label="Valor parceria ITE"
                placeholder="Valor parceria ITE"
                className={'inputfield w-full'}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
                value={formatToCurrency(form.values.valpcriteEmp)}
                onChange={(e) => {
                  e.target.value = formatCurrencyToDecimal(e.target.value)
                  form.handleChange(e)
                }}
              />
            </div>
            <div className="field col-12 md:col-6">
              <TextInput
                name="valpcrmonEmp"
                label="Valor parceria montadora"
                placeholder="Valor parceria montadora"
                className={'inputfield w-full'}
                onBlur={setFieldTouched}
                disabled={!edicaoHabilitada}
                value={formatToCurrency(form.values.valpcrmonEmp)}
                onChange={(e) => {
                  e.target.value = formatCurrencyToDecimal(e.target.value)
                  form.handleChange(e)
                }}
              />
            </div>
          </Fieldset>
        </div>
      </div>
    </div>
  )
}

export default DadosPrincInfoFinanceira
