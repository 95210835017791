import * as Yup from 'yup'
import CommonHelper from '../../helpers/CommonHelper'

export const RegistroValidator = () => {
  return (
    Yup.object().shape({
      empresaNomefantasia: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      empresaRazaoSocial: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      cep: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      usuarioNome: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      usuarioTelefone: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      usuarioEmail: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      cnpjEmp: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      cep: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      bairro: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      endereco: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      numero: Yup.string()
        .nullable()
        .required('Este campo é obrigatório!'),
      codufEmp: Yup.number()
        .nullable()
        .required('Este campo é obrigatório!'),
      codmunEmp: Yup.number()
        .nullable()
        .required('Este campo é obrigatório!'),
      custoTransacaoNovos: Yup.number()
        .nullable(),
      custoTransacaoUsados: Yup.number()
        .nullable(),
      ...(
        !CommonHelper.isUsuarioAdmin()
          ? {
            nomresfinEmp: Yup.string()
              .nullable()
              .required('Este campo é obrigatório!'),
            emaresfinEmp: Yup.string()
              .nullable()
              .required('Este campo é obrigatório!'),
            telresfinEmp: Yup.string()
              .nullable()
              .required('Este campo é obrigatório!')
          }
          : {}
      )
    })
  )
}
