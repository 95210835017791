import * as Yup from 'yup'

export const ParceiroValidator = () => {
  return Yup.object().shape({
    nomraz_pcr: Yup.string()
      .nullable()
      .required('O nome/razão social é obrigatório!'),
    cnpj_pcr: Yup.string()
      .nullable()
      .required('O CNPJ é obrigatório!')
  })
}
