import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CheckBoxInput from '../../components/inputs/CheckBoxInput'
import SearchInput from '../../components/inputs/SearchInput'
import TextInput from '../../components/inputs/TextInput'
import { baseForm } from '../../components/utils/BaseForm'
import DataList from '../../components/utils/DataList'
import FieldErrorMessage from '../../components/utils/FieldErrorMessage'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import RequiredLabel from '../../components/utils/RequiredLabel'
import { getEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatCnpj, formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import { useValidateInput } from '../../helpers/useValidateInput'
import EmpresaService from '../../services/cadastro/EmpresaService'
import UsuarioService from '../../services/cadastro/UsuarioService'
import RegistroService from '../../services/publicos/RegistroService'
import './_registroForm.scss'
import { RegistroValidator } from './RegistroValidator'
// import { InputMask } from 'primereact/inputmask'
import ReactInputMask from 'react-input-mask'
import SelectInput from '../../components/inputs/SelectInput'
import { getParceiroDTO } from '../../dtos/cadastro/ParceiroDTO'
import ParceiroService from '../../services/cadastro/ParceiroService'
import EstadosService from '../../services/localidade/EstadosService'
import MunicipioService from '../../services/localidade/MunicipioService'
import CheckBoxTermoAceite from '../cadastro/empresa/CheckBoxTermoAceite'
import CEPService from '../../services/localidade/CEPService'

const RegistroForm = ({ model, isConviteLojista, codigoEmpDespachante, nomeEmpDespachante, codigoConvite }) => {
  const [visibleVerificationEmail, setVisibleVerificationEmail] = useState(false)
  const [visibleEmpresasComMesmoEmailModal, setVisibleEmpresasComMesmoEmailModal] = useState(false)
  const [empresasComMesmoEmail, setEmpresasComMesmoEmail] = useState([])
  const [selectedEmpresas, setSelectedEmpresas] = useState([])
  const [parceiroSelecionado, setParceiroSelecionado] = useState(null)
  const history = useHistory()
  const [mask, setMask] = useState(null)
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])

  async function handleSubmitForm () {
    try {
      const empresasComMesmoEmail = await EmpresaService.getEmpresasByEmail(form.values.usuarioEmail)

      setEmpresasComMesmoEmail(empresasComMesmoEmail)

      if (empresasComMesmoEmail.length > 0 && isConviteLojista) {
        setVisibleEmpresasComMesmoEmailModal(true)
        return
      }
      if (isConviteLojista && form.values.perNovDesp === false && form.values.perUsaDesp === false) {
        showErrorMessage('É necessário permitir que o despachante realize pelo menos uma das operações!')
      } else {
        await handleRegister()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao tentar fazer o registro!')
    }
  }

  async function handleRegister (empresaJaCadastradaComEsseEmail = false) {
    try {
      if (empresaJaCadastradaComEsseEmail && selectedEmpresas.length === 0) {
        showWarnMessage('Selecione pelo menos uma empresa!')
        return
      }
      await RegistroService.save(form.values, selectedEmpresas.map(empresa => empresa.codigo_emp))
      if (!empresaJaCadastradaComEsseEmail) {
        try {
          let user = await UsuarioService.getUsuarioByEmail(form.values.usuarioEmail)

          // Se não tem usuário confirmado
          if (!user.isconf_usu) {
            await UsuarioService.enviarEmailConfirmacao(form.values.usuarioEmail)
            setVisibleVerificationEmail(true)
          }
        } catch (error) {
          if (CommonHelper.isUsuarioAdmin()) {
            showErrorMessage(
              'Ocorreu um erro ao tentar enviar o email de confirmação. ' +
              'Por favor tente fazer o re-envio pela tela de cadastro do usuário utilizando o usuário ADM para acessar a empresa cadastrada'
            )
          } else {
            showErrorMessage(
              'Ocorreu um erro ao tentar enviar o e-mail com o acesso para o usuário. Entre em contato com o suporte RenaveAuto para dar continuidade'
            )
          }
        }

        showSuccessMessage('Usuário cadastrado com sucesso!')
      } else {
        setVisibleEmpresasComMesmoEmailModal(false)
        showSuccessMessage('Despachante vinculado com o grupo de empresas com sucesso!')
      }
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao tentar fazer o registro!')
    }
  }

  function handleOK () {
    setVisibleVerificationEmail(false)
    history.push(`/login?email=${form.values.usuarioEmail}`)
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: RegistroValidator(),
    onSubmit: handleSubmitForm
  })

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  useEffect(async () => {
    form.setFieldValue('isConviteLojista', isConviteLojista)
    form.setFieldValue('codigoConvite', codigoConvite)

    if (codigoEmpDespachante) {
      form.setFieldValue('despachante', { codigo_emp: codigoEmpDespachante, nomfan_emp: nomeEmpDespachante })
      form.setFieldValue('renaveUsados', true)
      form.setFieldValue('renaveZeroKm', false)
      form.setFieldValue('renaveITE', false)
      form.setFieldValue('renaveMontadora', false)
      form.setFieldValue('perNovDesp', false)
      form.setFieldValue('perUsaDesp', false)
      form.setFieldValue('termoaceEmp', false)
    }
  }, [codigoEmpDespachante, nomeEmpDespachante, isConviteLojista, codigoConvite])

  const [sugestaoDespachantes, setSugestaoDespachantes] = useState([])
  async function sugerirDespachantes () {
    try {
      const empresaService = new EmpresaService()

      const despachantes = (await empresaService.filter(`descricaoFiltro=${form.values.despachante.nomfan_emp}`)).data

      setSugestaoDespachantes(despachantes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um despachante!')
    }
  }

  const getEstados = async () => {
    try {
      const estados = await EstadosService.getTodos()
      setEstados(estados)
    } catch (error) {}
  }

  const getCidadesPorEstado = async (estadoSigla) => {
    try {
      const cidades = await MunicipioService.getPorEstado(estadoSigla)
      setCidades(cidades)
    } catch (e) {}
  }

  useEffect(async () => {
    await getEstados()

    if (form.values.codufEmp) {
      const estado = await EstadosService.getPorId(form.values.codufEmp)
      await getCidadesPorEstado(estado.sigla_uf)
      form.setFieldValue('estado', estado)
    }
    if (form.values.codmunEmp) {
      const municipio = await MunicipioService.getPorId(form.values.codmunEmp)
      form.setFieldValue('municipio', municipio)
    }
  }, [])

  const handleChangeEstado = (e) => {
    const estado = e.target.value
    form.validateField('codufEmp')

    if (estado) {
      form.setFieldValue('codmunEmp', null)
      form.setFieldValue('codufEmp', estado.codigo_uf)
      form.setFieldValue('estado', estado)
      form.setFieldValue('municipio', {})

      getCidadesPorEstado(estado.sigla_uf)
    }
  }

  const handleChangeCidade = (e) => {
    const cidade = e.target.value

    if (cidade) {
      form.setFieldValue('codmunEmp', cidade.codigo_mun)
      form.setFieldValue('municipio', cidade)
    }
  }

  async function handleChangeCurrency (event) {
    await form.setFieldValue(event.target.name, formatCurrencyToDecimal(event.target.value))
  }

  const handleChangeMask = (ev) => {
    setCPFCNPJ(ev)
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')
    if ((nums.length < 3 && mask != null) || (nums.length === 11 && !isConviteLojista)) {
      setMask(null)

      ev.target.value = nums
    }
    form.handleChange(ev)
  }

  const setCPFCNPJ = (ev) => {
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')

    if (nums.length > 11 || isConviteLojista) {
      setMask('99.999.999/9999-99')
    } else if (nums.length >= 3) {
      setMask('999.999.999-99')
    }
    ev.target.value = nums
    setFieldTouched(ev)
  }

  function marcarTermoAceiteDespachante () {
    form.setFieldValue('termoaceEmp', !form.values.termoaceEmp)
  }

  function handleChangeDespachante (event) {
    form.setFieldValue('despachante', { nomfan_emp: event.target.value })
    desmarcarTermoAceiteDespachante()
  }

  function onSelectDespachante (e) {
    form.setFieldValue('despachante', e.value)
    desmarcarTermoAceiteDespachante()
  }

  function desmarcarTermoAceiteDespachante () {
    form.setFieldValue('termoaceEmp', false)
  }

  // * estado e função para controle de sugestão ao escreve no selecionar Parceiro
  const [sugestaoParceiro, setSugestaoParceiro] = useState([])
  async function sugerirParceiro (event) {
    try {
      const parceiroService = new ParceiroService()
      const res = await parceiroService.filter(
        `descricaoFiltro=${event.query}`
      )
      if (res?.data?.length <= 0) {
        showWarnMessage('Parceiro não encontrado!')
      }

      if (res?.data?.length > 0) {
        setSugestaoParceiro(res?.data)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um parceiro!')
    }
  }

  // * Seta string ou objeto
  function handleChangeOrSelectParceiro (e) {
    // * valor em string para função sugerirParceiro
    if (typeof e.value === 'string') {
      setParceiroSelecionado(e.value)
      return
    }

    // * Confere se o objeto ja existe no data
    // * Seta objeto ja existente ou altera para novo
    setParceiroSelecionado(e.value)
    form.setFieldValue('codpcr_emp', e.value.codigo_pcr)
    if (!e.value.codigo_pcr) {
      setParceiroSelecionado(null)
    }
  }

  // * Função para buscar informações do CEP
  const handleCepComplete = async (e) => {
    const cep = e.value
    if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
      const endereco = await CEPService.getCEP(cep)
      await getCidadesPorEstado(endereco.uf)
      const estado = await EstadosService.getPorSigla(endereco.uf)
      const municipio = await MunicipioService.getPorId(Number(endereco.ibge))

      form.setFieldValue('estado', { sigla_uf: endereco.uf })
      form.setFieldValue('municipio', municipio)
      form.setFieldValue('codufEmp', estado.codigo_uf)
      form.setFieldValue('codmunEmp', municipio.codigo_mun)
      form.setFieldValue('bairro', endereco.bairro)
      form.setFieldValue('endereco', endereco.logradouro)
      form.validateField('codufEmp')
      form.validateField('codmunEmp')
      form.validateField('bairro')
      form.validateField('endereco')
    }
  }

  return (
    <div className="registro page">
      <div className="registro body">
        <div className="flex align-items-center justify-content-between w-full">
          <div className="text-left flex flex-column align-items-center justify-content-center w-6 sm:w-5 md:w-4 lg:w-3">
            <object
              data="/assets/layout/images/Logo_RenaveAuto_com_texto.svg"
              className="w-full logo-svg"
            ></object>
            <label>Cadastro</label>
          </div>
          <div className="text-right w-6 sm:w-5 md:w-4 lg:w-3 flex align-items-center justify-content-end">
            <label onClick={() => history.push('/home')}>
              <i className="pi pi-arrow-up-right mr-2"></i> Acessar RenaveAuto
            </label>
          </div>
        </div>

        <div className="form-container flex flex-column align-items-center justify-content-center">
          <div className="image">
            <object className="icone-registro" data="/assets/layout/images/icone-registro.svg"></object>
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Nome de usuário" valid={isFormFieldValid('usuarioNome')} />
            <TextInput
              placeholder="Nome de usuário"
              name="usuarioNome"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('usuarioNome') }, 'inputfield w-full')}
              value={form.values.usuarioNome}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('usuarioNome')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Telefone" valid={isFormFieldValid('usuarioTelefone')} />
            <TextInput
              placeholder="Telefone"
              name="usuarioTelefone"
              value={form.values.usuarioTelefone}
              onChange={form.handleChange}
              mask="(99) 99999-999?9"
              className={classNames({ 'p-invalid': isFormFieldValid('usuarioTelefone') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('usuarioTelefone')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="E-mail" valid={isFormFieldValid('usuarioEmail')} />
            <TextInput
              placeholder="Email"
              name="usuarioEmail"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('usuarioEmail') }, 'inputfield w-full')}
              value={form.values.usuarioEmail}
              disabled={isConviteLojista}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('usuarioEmail')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('empresaRazaoSocial')} />
            <TextInput
              placeholder="Nome/Razão Social da empresa"
              name="empresaRazaoSocial"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('empresaRazaoSocial') }, 'inputfield w-full')}
              value={form.values.empresaRazaoSocial}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('empresaRazaoSocial')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Empresa" valid={isFormFieldValid('empresaNomefantasia')} />
            <TextInput
              placeholder="Nome fantasia da empresa"
              name="empresaNomefantasia"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('empresaNomefantasia') }, 'inputfield w-full')}
              value={form.values.empresaNomefantasia}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('empresaNomefantasia')} />
          </div>
          {/* <div className="field col-10 form-field no-margin">
              <RequiredLabel label="CNPJ" valid={isFormFieldValid('cnpjEmp2')}/>
              <TextInput
                name="cnpjEmp2"
                placeholder="CNPJ"
                value={form.values.cnpjEmp}
                onChange={form.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp2') }, 'inputfield w-full search-cnpj-input')}
                mask={form.values.cnpjEmp?.length > 11 ? '99.999.999/9999-99' : '999.999.999-99'}
                onBlur={setFieldTouched}
              />
              <FieldErrorMessage message={getFormErrorMessage('cnpjEmp2')}/>
            </div> */}
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label={isConviteLojista || !form.values.isDespachante ? 'CNPJ' : 'CPF/CNPJ'} valid={isFormFieldValid('cnpjEmp')} />
            <ReactInputMask
              mask={mask}
              maskChar="_"
              name="cnpjEmp"
              placeholder={isConviteLojista || !form.values.isDespachante ? 'CNPJ' : 'CPF/CNPJ'}
              value={form.values.cnpjEmp}
              onChange={handleChangeMask}
              className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp') }, 'p-inputtext p-component p-filled inputfield w-full')}
              onBlur={setCPFCNPJ}
            />
            <FieldErrorMessage message={getFormErrorMessage('cnpjEmp')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="CEP" valid={isFormFieldValid('cep')} />
            <TextInput
              placeholder="CEP"
              name="cep"
              value={form.values.cep}
              onChange={form.handleChange}
              onComplete={handleCepComplete}
              mask="99999-999"
              onBlur={setFieldTouched}
              className={classNames({ 'p-invalid': isFormFieldValid('cep') }, 'inputfield w-full')}
            />
            <FieldErrorMessage message={getFormErrorMessage('cep')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Bairro" valid={isFormFieldValid('bairro')} />
            <TextInput
              placeholder="Bairro"
              name="bairro"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('bairro') }, 'inputfield w-full')}
              value={form.values.bairro}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('bairro')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Endereço" valid={isFormFieldValid('endereco')} />
            <TextInput
              placeholder="Endereço"
              name="endereco"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('endereco') }, 'inputfield w-full')}
              value={form.values.endereco}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('endereco')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Número" valid={isFormFieldValid('numero')} />
            <TextInput
              placeholder="Número"
              name="numero"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('numero') }, 'inputfield w-full')}
              value={form.values.numero}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('numero')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <label>Complemento</label>
            <TextInput
              placeholder="Complemento"
              name="complemento"
              onChange={form.handleChange}
              className='inputfield w-full'
              value={form.values.complemento}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Estado" valid={isFormFieldValid('codufEmp')}/>
            <SelectInput
              value={form.values.estado}
              options={estados}
              onChange={handleChangeEstado}
              placeholder="Selecione"
              name="codufEmp"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_uf"
              dataKey="sigla_uf"
              noFloatLabel
              className={classNames({ 'p-invalid': isFormFieldValid('codufEmp') }, 'inputfield w-full')}
              onBlur={() => form.setFieldTouched('codufEmp')}
            />
            <FieldErrorMessage message={getFormErrorMessage('codufEmp')}/>
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel label="Município" valid={isFormFieldValid('codmunEmp')}/>
            <SelectInput
              value={form.values.municipio}
              options={cidades}
              onChange={handleChangeCidade}
              placeholder="Selecione"
              name="codmunEmp"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_mun"
              dataKey="codigo_mun"
              noFloatLabel
              className={classNames({ 'p-invalid': isFormFieldValid('codmunEmp') }, 'inputfield w-full')}
              onBlur={() => form.setFieldTouched('codmunEmp')}
            />
            <FieldErrorMessage message={getFormErrorMessage('codmunEmp')}/>
          </div>
          {!isConviteLojista && (
            <div className="field col-10 form-field no-margin">
              <SearchInput
              // * props
                name='codpcr_emp'
                field='nomraz_pcr'
                className="inputfield w-full"
                label="Parceiro"
                placeholder="Parceiro"
                disabled={isConviteLojista}

                // * Controle do valor e seleção da empresa de faturamento
                value={typeof parceiroSelecionado === 'string' ? parceiroSelecionado : parceiroSelecionado?.nomraz_pcr}
                onSelect={handleChangeOrSelectParceiro}

                // * Controle de sugestão do autocomplete
                suggestions={sugestaoParceiro}
                completeMethod={sugerirParceiro}
                onChange={handleChangeOrSelectParceiro}

                // * controle do FiltroModal
                filtroTitle="Pesquisa de parceiros"
                service={ParceiroService}
                model={getParceiroDTO}
                primaryKeyName='codigo_pcr'
                columns={[
                  { campo: 'codigo_pcr', nome: 'Código' },
                  { campo: 'nomraz_pcr', nome: 'Nome' },
                  { campo: 'cnpj_pcr', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
                ]}
              />
            </div>
          )}
          {!isConviteLojista && (
            <div className="field col-10 form-field">
              <div className="field form-field no-margin">
                <label>Custo por transação para veículos novos</label>
                <TextInput
                  placeholder="Custo por transação do Renave"
                  name="custoTransacaoNovos"
                  onChange={handleChangeCurrency}
                  className='inputfield w-full'
                  value={formatToCurrency(form.values.custoTransacaoNovos || 0.00)}
                  onBlur={setFieldTouched}
                />
                {/* <FieldErrorMessage message={getFormErrorMessage('custoTransacaoNovos')} /> */}
              </div>
              <div className="field form-field no-margin">
                <label>Custo por transação para veículos usados</label>
                <TextInput
                  placeholder="Custo por transação do Renave"
                  name="custoTransacaoUsados"
                  onChange={handleChangeCurrency}
                  className={classNames({ 'p-invalid': isFormFieldValid('custoTransacaoUsados') }, 'inputfield w-full')}
                  value={formatToCurrency(form.values.custoTransacaoUsados || 0.00)}
                  onBlur={setFieldTouched}
                />
                {/* <FieldErrorMessage message={getFormErrorMessage('custoTransacaoUsados')} /> */}
              </div>
            </div>
          )}
          {isConviteLojista && (
            <>
              <div className="field col-10 form-field no-margin">
                <SearchInput
                  //AutoComplete
                  label="Despachante"
                  field="nomfan_emp"
                  value={form.values.despachante?.nomfan_emp && form.values.despachante}
                  suggestions={sugestaoDespachantes}
                  completeMethod={sugerirDespachantes}
                  onChange={handleChangeDespachante}
                  onSelect={onSelectDespachante}
                  className="inputfield w-full"
                  disabled={isConviteLojista}
                  //FiltroModal
                  filtroTitle="Pesquisa de despachantes"
                  filtersDefault={{ apenasDespachantes: true, ignorarGrupo: true }}
                  service={EmpresaService}
                  model={getEmpresaDTO}
                  columns={[
                    { campo: 'nomfan_emp', nome: 'Nome' },
                    { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
                  ]}
                />
              </div>
              <>
                <div className="field col-10 form-field no-margin">
                  <CheckBoxInput
                    label="Permitir despachante realizar operações para veículos novos?"
                    value={form.values.perNovDesp}
                    checked={form.values.perNovDesp}
                    onChange={() => form.setFieldValue('perNovDesp', !form.values.perNovDesp)}
                  />
                </div>
                <div className="field col-10 form-field no-margin">
                  <CheckBoxInput
                    label="Permitir despachante realizar operações para veículos usados?"
                    value={form.values.perUsaDesp}
                    checked={form.values.perUsaDesp}
                    onChange={() => form.setFieldValue('perUsaDesp', !form.values.perUsaDesp)}
                  />
                </div>
                <div className="field col-10 form-field no-margin">
                  <CheckBoxTermoAceite
                    value={form.values.termoaceEmp}
                    checked={form.values.termoaceEmp}
                    onChange={marcarTermoAceiteDespachante}
                  />
                </div>
              </>
            </>
          )}
          <div className="field col-10 form-field no-margin">
            <RequiredLabel
              label="Nome do responsável financeiro"
              valid={isFormFieldValid('nomresfinEmp')}
              required={!CommonHelper.isUsuarioAdmin()}
            />
            <TextInput
              value={form.values.nomresfinEmp}
              name="nomresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('nomresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              placeholder="Nome do responsável financeiro"
            />
            <FieldErrorMessage message={getFormErrorMessage('nomresfinEmp')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel
              label="Email do responsável financeiro"
              valid={isFormFieldValid('emaresfinEmp')}
              required={!CommonHelper.isUsuarioAdmin()}
            />
            <TextInput
              value={form.values.emaresfinEmp}
              name="emaresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('emaresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              placeholder="Email do responsável financeiro"
            />
            <FieldErrorMessage message={getFormErrorMessage('emaresfinEmp')} />
          </div>
          <div className="field col-10 form-field no-margin">
            <RequiredLabel
              label="Telefone do responsável financeiro"
              valid={isFormFieldValid('telresfinEmp')}
              required={!CommonHelper.isUsuarioAdmin()}
            />
            <TextInput
              value={form.values.telresfinEmp}
              name="telresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('telresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              mask="(99) 99999-999?9"
              placeholder="Telefone do responsável financeiro"
            />
            <FieldErrorMessage message={getFormErrorMessage('telresfinEmp')} />
          </div>
          {!isConviteLojista && (
            <>
              <div className="field col-10 form-field no-margin">
                <CheckBoxInput
                  label="É despachante?"
                  value={form.values.isDespachante}
                  checked={form.values.isDespachante}
                  onChange={() => form.setFieldValue('isDespachante', !form.values.isDespachante)}
                />
              </div>
              <div className="field col-10 form-field no-margin">
                <CheckBoxInput
                  label="Renave 0km"
                  value={form.values.renaveZeroKm}
                  checked={form.values.renaveZeroKm}
                  onChange={() => form.setFieldValue('renaveZeroKm', !form.values.renaveZeroKm)}
                />
              </div>
              <div className="field col-10 form-field no-margin">
                <CheckBoxInput
                  label="Renave usados"
                  value={form.values.renaveUsados}
                  checked={form.values.renaveUsados}
                  onChange={() => form.setFieldValue('renaveUsados', !form.values.renaveUsados)}
                />
              </div>
              <div className="field col-10 form-field no-margin">
                <CheckBoxInput
                  label="Renave ITE"
                  value={form.values.renaveITE}
                  checked={form.values.renaveITE}
                  onChange={() => form.setFieldValue('renaveITE', !form.values.renaveITE)}
                />
              </div>
              <div className="field col-10 form-field no-margin">
                <CheckBoxInput
                  label="Renave montadora"
                  value={form.values.renaveMontadora}
                  checked={form.values.renaveMontadora}
                  onChange={() => form.setFieldValue('renaveMontadora', !form.values.renaveMontadora)}
                />
              </div>
            </>
          )}
          <div className="button-container mb-4">
            <Button
              label="Cadastrar"
              icon="pi pi-check-circle"
              iconPos="right"
              onClick={() => form.submitForm()}
            />
          </div>
        </div>

      </div>
      <Modal
        header="Verificação de e-mail"
        visible={visibleVerificationEmail}
        width="45"
        onHide={handleOK}
        footer={
          <Button
            label="Acessar"
            icon="pi pi-check"
            onClick={handleOK}
          />
        }
      >
        <div>
          <div><p>Você recebeu um e-mail com sua senha temporária. Acesse o sistema clicando no botão abaixo!</p></div>
        </div>
      </Modal>
      <Modal
        visible={visibleEmpresasComMesmoEmailModal}
        onHide={() => setVisibleEmpresasComMesmoEmailModal(false)}
        width={50}
        header="Foi encontrada uma ou mais empresas com esse email!"
        footer={
          <Button
            label="Dar permissão"
            icon="pi pi-check"
            onClick={async () => await handleRegister(true)}
          />
        }
      >
        <p>Selecione as empresas que você deseja dar permissão para o despachante em questão.</p>
        <DataList
          className="selection-datalist"
          data={empresasComMesmoEmail}
          selected={selectedEmpresas}
          onSelectionChange={e => setSelectedEmpresas(e.value)}
          dataKey="codigo_emp"
          responsiveLayout="scroll"
          responsive={true}
          selectionMode="checkbox"
        >
          <Column
            style={{ width: '20%' }}
            selectionMode="multiple"
          />
          <Column
            header="Nome"
            field="nomfan_emp"
            style={{ width: '40%' }}
            sortable
            headerClassName="nomfan_emp"
          />
          <Column
            header="CNPJ"
            field="cnpj_emp"
            body={data => formatCnpj(data.cnpj_emp)}
            style={{ width: '40%' }}
            sortable
            headerClassName="cnpj_emp"
          />
        </DataList>
      </Modal>
    </div>
  )
}

export default RegistroForm
