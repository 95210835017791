import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { formatDateTime } from '../../../helpers/formaters'
import { Tooltip } from 'primereact/tooltip'
import './_importarXmls.scss'
import LoteXmlService from '../../../services/zeroKm/xml/LoteXmlService'
import List from '../../../classes/List'
import { getLoteXmlDTO } from '../../../dtos/xml/LoteXmlDTO'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'

class ImportarXmls extends List {
  constructor (props) {
    super(
      props,
      getLoteXmlDTO,
      LoteXmlService,
      'lote-xml',
      'codigo',
      'finalizados'
    )

    this.state = {
      ...this.state,
      interval: null
    }

    this.setColumns([
      {
        key: 'codigo',
        component: (
          <Column
            key='codigo'
            header="Lote"
            field="codigo"
            style={{ width: '18%', maxWidth: '18%' }}
            sortable
            headerClassName="codigo"
          />
        )
      },
      {
        key: 'datahoraimportacao',
        component: (
          <Column
            key='datahoraimportacao'
            header="Data/Hora Importação"
            field="datahoraimportacao"
            body={data => formatDateTime(data.datahoraimportacao)}
            style={{ width: '12%', maxWidth: '12%' }}
            sortable
            headerClassName="datahoraimportacao"
          />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            key='nome_usu'
            header="Usuário"
            field="nome_usu"
            style={{ width: '20%', maxWidth: '20%' }}
            sortable
            headerClassName="nome_usu"
          />
        )
      },
      {
        key: 'totalarquivos',
        component: (
          <Column
            key='totalarquivos'
            header="Arquivos"
            field="totalarquivos"
            style={{ width: '7%', maxWidth: '7%' }}
            sortable
            headerClassName="totalarquivos"
          />
        )
      },
      {
        key: 'totalentradas',
        component: (
          <Column
            key='totalentradas'
            header="Entradas"
            field="totalentradas"
            style={{ width: '7%', maxWidth: '7%' }}
            sortable
            headerClassName="totalentradas"
          />

        )
      },
      {
        key: 'totalsaidas',
        component: (
          <Column
            key='totalsaidas'
            header="Saídas"
            field="totalsaidas"
            style={{ width: '7%', maxWidth: '7%' }}
            sortable
            headerClassName="totalsaidas"
          />
        )
      },
      {
        key: 'totaltransferencias',
        component: (
          <Column
            key='totaltransferencias'
            header="Transferências"
            field="totaltransferencias"
            style={{ width: '11%', maxWidth: '11%' }}
            sortable
            headerClassName="totaltransferencias"
          />
        )
      },
      {
        key: 'totalduplicados',
        component: (
          <Column
            key='totalduplicados'
            header="Duplicados"
            field="totalduplicados"
            style={{ width: '7%', maxWidth: '7%' }}
            sortable
            headerClassName="totalduplicados"
          />
        )
      },
      {
        key: 'totaldescartados',
        component: (
          <Column
            key='totaldescartados'
            header="Descartados"
            field="totaldescartados"
            style={{ width: '12%', maxWidth: '12%' }}
            sortable
            headerClassName="totaldescartados"
          />
        )
      }

    ])
  }

  onSelectOption = (option) => {
    let { filter } = this.state
    this.setState({ opcaoSelecionada: option })

    switch (option) {
    case 'finalizados':
      filter.finalizado = 'sim'
      break
    case 'processando':
      filter.finalizado = 'nao'
      break
    }

    this.setState({ filter }, this.onFilter)
  }

  handleUploadXml = async (e) => {
    const files = e.target.files
    if (files) {
      try {
        await LoteXmlService.processarXmls(files)

        await this.onFilter()

        const fileInput = document.getElementById('file-upload')
        fileInput.value = null
        showSuccessMessage('Arquivos enviados com sucesso!')
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao enviar os arquivos')
      }
    }
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>
            Importar XML's
          </h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'finalizados' }, 'filter-option')}
              onClick={() => this.onSelectOption('finalizados')}>
              <span className="option-label">Finalizados</span>
              <div className="option-quantity">
                {this.state.quantidades.totalFinalizado || 0}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'processando' }, 'filter-option')}
              onClick={() => this.onSelectOption('processando')}>
              <span className="option-label">Processando</span>
              <div className="option-quantity">
                {this.state.quantidades.totalPendente || 0}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <Button
              icon="pi pi-refresh"
              onClick={() => this.onFilter()}
              className="mb-3 mr-3"
            />
            <label htmlFor="file-upload" className="button-file-upload mb-3">
              <i className="pi pi-cloud-upload mr-2"></i> Importar XML's
            </label>
            <input
              className="xml-inputfile"
              id="file-upload"
              type="file"
              accept="text/xml"
              multiple
              onChange={this.handleUploadXml}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            onSelect={this.onSelect}
            onRowSelect={(e) => this.onView(e.data)}
            rows={rows}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            responsive
            showFilter
            filterPlaceholder="Procurar lote pelo chassi"
            filterName="chassi"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <Tooltip className="icone-info-legenda-tooltip" target=".icone-info legenda" position="bottom" >
          <>
            <div>Arquivos - é o número de xml's selecionados naquele lote.</div>
            {'\n'}
            <div>Entradas - são todos os xml’s onde:</div>
            <div> • A NF está autorizada pela sefaz</div>
            <div> • a finalidade  tem que ser 1  “NFe-normal”</div>
            <div> • o ambiente de emissão é produção</div>
            <div> • o tipo da nf, tem que ser de saída. (venda da fábrica para o concessionário)</div>
            <div> • a nf é de veículo, ou seja, contem a tag veicprod</div>
            <div> • o destinatário da nf tem que ser a empresa logada</div>
            <div> • O chassi deve estar pendente de confirmação de entrada</div>
            {'\n'}
            <div>Saídas - são todos os xml’s onde:</div>
            <div> • A NF está autorizada pela sefaz</div>
            <div> • a finalidade  tem que ser 1  “NFe-normal”</div>
            <div> • o ambiente de emissão é produção</div>
            <div> • o tipo da nf, tem que ser de saída. (venda do concessionário para o cliente)</div>
            <div> • a nf é de veículo, ou seja, contem a tag veicprod</div>
            <div> • o emitente da nf tem que ser a empresa logada</div>
            <div> • O chassi deve estar no estoque renave</div>
            {'\n'}
            <div>Duplicados - são todos os xml’s onde:</div>
            <div> • Já foram importados em algum lote</div>
            {'\n'}
            <div>Descartados - são todos os xml’s onde: </div>
            <div> • Não atendem a nenhum dos requisitos anterior</div>
          </>
        </Tooltip>
      </Page>
    )
  }
}

export default ImportarXmls
