import { Fieldset } from 'primereact/fieldset'
import { classNames } from 'primereact/utils'
import React from 'react'
import SancesInputCFPCNPJmask from '../../../../../../components/inputs/MaskedInputs/CPFCNPJ'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatCurrencyToDecimal, formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'

const DadosPrincipaisPcr = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  const handleChangeMask = (ev) => {
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')
    ev.target.value = nums
    form.handleChange(ev)
  }

  return (
    <div className="formgrid grid">

      {/* FORM */}

      <div className="field col-12 md:col-6">
        <RequiredLabel label={'CNPJ'} valid={isFormFieldValid('cnpj_pcr')}/>
        <SancesInputCFPCNPJmask
          placeholder='Insira o CNPJ'
          name="cnpj_pcr"
          value={form.values.cnpj_pcr}
          onChange={handleChangeMask}
          disabled={!edicaoHabilitada}
          formFieldValid={'cnpj_pcr'}
          isFormFieldValid={isFormFieldValid('cnpj_pcr')}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpj_pcr') }, 'p-inputtext p-component p-filled inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpj_pcr')}/>
      </div>
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomraz_pcr')}/>
        <TextInput
          placeholder="Insira o nome/razão social"
          value={form.values.nomraz_pcr}
          name="nomraz_pcr"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomraz_pcr') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomraz_pcr')}/>
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="E-mail"
          placeholder="Insira o e-mail"
          value={form.values.email_pcr}
          name="email_pcr"
          onChange={form.handleChange}
          className={'inputfield w-full'}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Telefone"
          placeholder="Insira o telefone"
          name="tel_pcr"
          value={form.values.tel_pcr}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>

      {/* VALORES DE REPASSE */}

      <div className="field col-12">
        <Fieldset
          legend="Valor de repasse para o parceiro"
          className='custom-fieldset'
        >
          <div className="field col-12 md:col-6">
            <TextInput
              name="valusa_pcr"
              label="Valor parceria usados"
              placeholder="Valor parceria usados"
              className={'inputfield w-full'}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valusa_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
          </div>
          <div className="field col-12 md:col-6">
            <TextInput
              name="valnov_pcr"
              label="Valor parceria novos"
              placeholder="Valor parceria novos"
              className={'inputfield w-full'}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valnov_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
          </div>
          <div className="field col-12 md:col-6">
            <TextInput
              name="valite_pcr"
              label="Valor parceria ITE"
              placeholder="Valor parceria ITE"
              className={'inputfield w-full'}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valite_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
          </div>
          <div className="field col-12 md:col-6">
            <TextInput
              name="valmot_pcr"
              label="Valor parceria montadora"
              placeholder="Valor parceria montadora"
              className={'inputfield w-full'}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valmot_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
          </div>
        </Fieldset>
      </div>

      {/* OBSERVAÇÕES */}

      <div className="field col-12">
        <TextAreaInput
          label="Observações financeiras"
          value={form.values.obsfin_pcr}
          name="obsfin_pcr"
          onChange={form.handleChange}
          className={'inputfield w-full'}
          disabled={!edicaoHabilitada}
          placeholder="Digite aqui..."
          rows={6}
        />
      </div>
    </div>
  )
}

export default DadosPrincipaisPcr
