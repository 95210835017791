import React from 'react'
import './_dropdownButton.scss'
import { Divider } from 'primereact/divider'

const DropdownItem = (props) => {
  return (
    <div>
      {!props.first && (
        <Divider style={{ margin: '0.5rem 0' }} type="dashed"/>
      )}
      <div onClick={() => props.onClick()} className="dropdown-item" id="dropdown">
        <a className="menu-item" id="dropdown">
          {props.label}
        </a>
      </div>
    </div>
  )
}

export default DropdownItem
