import { Button } from 'primereact/button'
import React, { useState } from 'react'
import OutsideAlerter from '../../utils/OutsideAlerter'
import './_dropdownButton.scss'
import DropdownItem from './DropdownItem'
import { Divider } from 'primereact/divider'

const DropdownMenuHorizontal = (props) => {
  const [open, setOpen] = useState(false)

  const groupedItems = []
  for (let i = 0; i < props.items.length; i += 3) {
    groupedItems.push(props.items.slice(i, i + 3))
  }

  return (
    <>
      {open && (
        <OutsideAlerter command={() => setOpen(false)}>
          <div className={'dropdown horizontal-dropdown'}>
            {groupedItems.map((group, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Divider layout="vertical" className='custom-vertical-style' />}
                <div className="dropdown-group">
                  {group.map((item, idx) => (
                    <DropdownItem
                      key={idx}
                      label={item.label}
                      onClick={() => {
                        if (props.data) {
                          item.command(props.data)
                        } else {
                          item.command()
                        }
                        setOpen(false)
                      }}
                    />
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </OutsideAlerter>
      )}
      <Button
        onClick={() => setOpen(!open)}
        label={props.label}
        icon={props.icon}
        className={`dropdown-button ${props.onlyIcon ? 'only-icon' : ''} ${props.className}`}
      />
    </>
  )
}

export default DropdownMenuHorizontal
