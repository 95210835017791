import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { TreeSelect } from 'primereact/treeselect'
import React from 'react'
import XLSX from 'xlsx'
import List from '../../../../classes/List'
import DateInput from '../../../../components/inputs/DateInput'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/utils/DataList'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import { getHistoricoMovimentacaoDTO } from '../../../../dtos/zeroKm/HistoricoMovimentacaoDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCpfOrCnpj, formatDate, formatToCurrency } from '../../../../helpers/formaters'
import { getStore } from '../../../../redux/store'
import ParceiroService from '../../../../services/cadastro/ParceiroService'
import UsuarioService from '../../../../services/cadastro/UsuarioService'
import HistoricoMovimentacaoService from '../../../../services/zeroKm/historico/HistoricoMovimentacaoService'

class ParceiroMovimentacao extends List {
  constructor (props) {
    super(
      props,
      getHistoricoMovimentacaoDTO,
      HistoricoMovimentacaoService,
      '',
      '',
      't'
    )

    const currentDate = new Date()
    const lastMonth = currentDate.getMonth() - 1
    // Primeira data do mês passado (1º dia do mês passado)
    const dataMovimentacaoInicial = new Date(currentDate.setMonth(lastMonth, 1))
    dataMovimentacaoInicial.setHours(0, 0, 0, 0) // Reseta a hora para 00:00:00
    // Última data do mês passado (último dia do mês passado)
    const dataMovimentacaoFinal = new Date(currentDate.setMonth(lastMonth + 1, 0)) // Define o último dia do mês anterior ao mês atual
    dataMovimentacaoFinal.setHours(23, 59, 59, 999) // Reseta para o último minuto do dia

    this.state = {
      ...this.state,
      dataMovimentacaoInicial: dataMovimentacaoInicial,
      dataMovimentacaoFinal: dataMovimentacaoFinal,
      companies: [],
      parceiros: [],
      selectedCompanies: {
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      },
      selectedParceiros: {
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      },
      filter: {
        ...this.state.filter,
        consultaParceria: true,
        dataMovimentacaoInicial: dataMovimentacaoInicial,
        dataMovimentacaoFinal: dataMovimentacaoFinal
      }
    }

    //#region Criando colunas.

    this.setColumns([
      {
        key: 'nomraz_pcr',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='nomraz_pcr'
            field="nomraz_pcr"
            header="Nome Razão Parceiro"
            sortable
            headerClassName="nomraz_pcr"
          />
        )
      },
      {
        key: 'cnpj_pcr',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='cnpj_pcr'
            field="cnpj_pcr"
            header="CNPJ Parceiro"
            body={data => data.cnpj_pcr ? formatCpfOrCnpj(data.cnpj_pcr) : ''}
            sortable
            headerClassName="cnpj_pcr"
          />
        )
      },
      {
        key: 'nomfan_emp',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='nomfan_emp'
            field="nomfan_emp"
            header="Empresa"
            sortable
            headerClassName="nomfan_emp" />
        )
      },
      {
        key: 'cnpj_emp',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='cnpj_emp'
            field="cnpj_emp"
            header="CPF/CNPJ Empresa"
            body={data => data.cnpj_emp ? formatCpfOrCnpj(data.cnpj_emp) : ''}
            sortable
            headerClassName="cnpj_emp"
          />
        )
      },

      {
        key: 'isdesp_emp',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='isdesp_emp'
            field="isdesp_emp"
            header="Empresa despachante?"
            body={data => data.isdesp_emp ? 'Sim' : 'Não'}
            sortable
            headerClassName="isdesp_emp" />
        )
      },

      {
        key: 'mov_seminovos',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='mov_seminovos'
            field="mov_seminovos"
            header="Mov. Seminovos"
            sortable
            headerClassName="mov_seminovos" />
        )
      },
      {
        key: 'val_seminovos',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='val_seminovos'
            field="val_seminovos"
            header="Valor - Seminovos"
            body={data => formatToCurrency(data.val_seminovos)}
            sortable
            headerClassName="val_seminovos" />
        )
      },
      {
        key: 'valtot_seminovos',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='valtot_seminovos'
            field="valtot_seminovos"
            header="Valor total - Seminovos"
            body={data => formatToCurrency(data.valtot_seminovos)}
            sortable
            headerClassName="valtot_seminovos" />
        )
      },

      {
        key: 'mov_zerokm',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='mov_zerokm'
            field="mov_zerokm"
            header="Mov. 0KM"
            sortable
            headerClassName="mov_zerokm" />
        )
      },
      {
        key: 'val_zerokm',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='val_zerokm'
            field="val_zerokm"
            header="Valor - 0KM"
            body={data => formatToCurrency(data.val_zerokm)}
            sortable
            headerClassName="val_zerokm" />
        )
      },
      {
        key: 'valtot_zerokm',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='valtot_zerokm'
            field="valtot_zerokm"
            header="Valor total - 0KM"
            body={data => formatToCurrency(data.valtot_zerokm)}
            sortable
            headerClassName="valtot_zerokm" />
        )
      },

      {
        key: 'mov_ite',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='mov_ite'
            field="mov_ite"
            header="Mov. ITE"
            sortable
            headerClassName="mov_zerokm" />
        )
      },
      {
        key: 'val_ite',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='val_ite'
            field="val_ite"
            header="Valor - ITE"
            body={data => formatToCurrency(data.val_ite)}
            sortable
            headerClassName="val_ite" />
        )
      },
      {
        key: 'valtot_ite',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='valtot_ite'
            field="valtot_ite"
            header="Valor total - ITE"
            body={data => formatToCurrency(data.valtot_ite)}
            sortable
            headerClassName="valtot_ite" />
        )
      },

      {
        key: 'mov_montadora',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='mov_montadora'
            field="mov_montadora"
            header="Mov. Montadora"
            sortable
            headerClassName="mov_zerokm" />
        )
      },
      {
        key: 'val_montadora',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='val_montadora'
            field="val_montadora"
            header="Valor - Montadora"
            body={data => formatToCurrency(data.val_montadora)}
            sortable
            headerClassName="val_montadora" />
        )
      },
      {
        key: 'valtot_montadora',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='valtot_montadora'
            field="valtot_montadora"
            header="Valor total - Montadora"
            body={data => formatToCurrency(data.valtot_montadora)}
            sortable
            headerClassName="valtot_montadora" />
        )
      },

      {
        key: 'valtotgeral',
        component: (
          <Column
            style={{ minWidth: '200px' }}
            key='valtotgeral'
            field="valtotgeral"
            header="Valor total - Geral"
            body={data => formatToCurrency(data.valtotgeral)}
            sortable
            headerClassName="valtotgeral" />
        )
      }
    ])

    //#endregion
  }

  async componentDidMount () {
    this.addEventListeners()

    const { data: parceirosData } = await new ParceiroService().getAll()
    const parceirosMapped = parceirosData.map((parceiro, idx) => {
      return {
        key: `0-${idx}`,
        label: parceiro?.nomraz_pcr,
        data: parceiro?.codigo_pcr
      }
    })
    const parceiros = [{
      key: 0,
      label: 'Parceiros',
      children: [...parceirosMapped]
    }]

    const empresasAcesso = await UsuarioService.empresasAcessoUsuarioLogado()
    const empresasAcessoAtivas = empresasAcesso.filter((empresa) => empresa.ativo_emp)

    // Colocando a empresa logada como primeiro registro do array
    const empresaLogada = empresasAcessoAtivas.filter((empresa) => empresa.codigo_emp === getStore().empresaLogada.codigo_emp).shift()
    empresasAcessoAtivas.splice(empresasAcessoAtivas.indexOf(empresaLogada), 1)
    empresasAcessoAtivas.unshift(empresaLogada)
    const empresas = empresasAcessoAtivas.map((empresa, idx) => {
      return {
        key: `0-${idx}`,
        label: empresa?.nomfan_emp,
        data: empresa?.codigo_emp
      }
    })

    const companies = [{
      key: 0,
      label: 'Empresas',
      children: [...empresas]
    }]

    const fullSelectedCompanies = {
      0: { checked: true, partialChecked: false }
    }

    companies[0].children.forEach((empresa) => {
      fullSelectedCompanies[empresa.key] = { checked: true, partialChecked: false }
    })

    this.setState({ companies, parceiros, selectedCompanies: fullSelectedCompanies })
  }

  handleExportarExcel = async () => {
    const {
      dataMovimentacaoInicial,
      dataMovimentacaoFinal,
      selectedParceiros,
      parceiros
    } = this.state

    if (!dataMovimentacaoInicial || !dataMovimentacaoFinal) return showErrorMessage('Obrigatório o preenchimento das datas para gerar o excel')

    this.aplicaVariaveisNoFilter()

    const historicoMovimentacaoService = new HistoricoMovimentacaoService()
    const response = await historicoMovimentacaoService.filter(this.toFilter())

    if (!response.data.length) return showErrorMessage('Sem registros para exportar, tente alterar os filtros!')

    const parceiro = []
    for (const registro of response.data) {
      const row = {
        ...(
          dataMovimentacaoInicial
            ? { 'Data Inicial': formatDate(dataMovimentacaoInicial) }
            : {}
        ),
        ...(
          dataMovimentacaoFinal
            ? { 'Data Final': formatDate(dataMovimentacaoFinal) }
            : {}
        ),

        'Nome Parceiro': registro.nomraz_pcr,
        'CNPJ Parceiro': formatCpfOrCnpj(registro.cnpj_pcr),

        Empresa: registro.nomfan_emp,
        'CPF/CNPJ Empresa': formatCpfOrCnpj(registro.cnpj_emp),
        'Empresa despachante?': registro.isdesp_emp ? 'Sim' : 'Não',

        'Mov. Seminovos': registro.mov_seminovos,
        'Valor - Seminovos': registro.val_seminovos,
        'Valor total - Seminovos': registro.valtot_seminovos,

        'Mov. 0KM': registro.mov_zerokm,
        'Valor - 0KM': registro.val_zerokm,
        'Valor total - 0KM': registro.valtot_zerokm,

        'Mov. ITE': registro.mov_ite,
        'Valor - ITE': registro.val_ite,
        'Valor total - ITE': registro.valtot_ite,

        'Mov. Montadora': registro.mov_montadora,
        'Valor - Montadora': registro.val_montadora,
        'Valor total - Montadora': registro.valtot_montadora,

        'Valor total - Geral': registro.valtotgeral
      }
      parceiro.push(row)
    }

    let nomeParceiro = ''
    const parceirosSelecionados = Object.keys(selectedParceiros).filter(pal => pal !== '0')

    if (Object.keys(selectedParceiros).filter(pal => pal !== '0').length === 1) {
      nomeParceiro = parceiros[0].children.find((obj) => obj.key === parceirosSelecionados[0])?.label
    }

    const workSheet = XLSX.utils.json_to_sheet(parceiro)

    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }

    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })

    XLSX.writeFile(
      workBook,
      `RenaveAuto_${!!nomeParceiro ? nomeParceiro : 'PARCEIROS'}_DATAINICIAL${
        formatDate(dataMovimentacaoInicial).replaceAll('/', '')
      }_DATAFINAL${
        formatDate(dataMovimentacaoFinal).replaceAll('/', '')
      }.xlsx`
    )
  }

  validateFilter = (filter) => {
    if (!filter.dataMovimentacaoInicial || !filter.dataMovimentacaoFinal) {
      return 'Preencha os campos de data inicial e final para buscar os registros!'
    }
    if (!filter.idsEmpresas.length) {
      return 'Selecione ao menos uma empresa para buscar os registros!'
    }
    if (!filter.idsParceiros.length) {
      return 'Selecione ao menos um parceiro para buscar os registros!'
    }
    return null
  }

  customOnFilter = async () => {
    const { filter } = this.state

    const validationMessage = this.validateFilter(filter)
    if (validationMessage) return showWarnMessage(validationMessage)

    this.onFilter()
  }

  aplicaVariaveisNoFilter = () => {
    const { filter, selectedCompanies, companies, selectedParceiros, parceiros } = this.state

    filter.dataMovimentacaoInicial = CommonHelper.dateToAmerican(this.state.dataMovimentacaoInicial)
    filter.dataMovimentacaoFinal = CommonHelper.dateToAmerican(this.state.dataMovimentacaoFinal)

    filter.idsEmpresas = CommonHelper.convertObjectToList(selectedCompanies, companies)
    filter.idsParceiros = CommonHelper.convertObjectToList(selectedParceiros, parceiros)

    this.setState({ filter })
  }

  onFilterClick = () => {
    this.aplicaVariaveisNoFilter()
    this.customOnFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12">
        <label className="label">Data da movimentação</label>
        <div className="formgrid grid">
          <div className="col-6">
            <DateInput
              name="dataMovimentacaoInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoInicial}
              onChange={(e) => { console.log(e.target.value); this.setState({ dataMovimentacaoInicial: e.target.value }) }} />
          </div>
          <div className="col-6">
            <DateInput
              name="dataMovimentacaoFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoFinal}
              onChange={(e) => this.setState({ dataMovimentacaoFinal: e.target.value })} />
          </div>
        </div>
      </div>

      <div className="field col-12">
        <label className="label">Empresas</label>
        <TreeSelect
          value={this.state.selectedCompanies}
          options={this.state.companies}
          onChange={(e) => this.setState({ selectedCompanies: e.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione uma ou mais empresas"
          filter
        />
      </div>

      <div className="field col-12">
        <label className="label">Parceiros</label>
        <TreeSelect
          value={this.state.selectedParceiros}
          options={this.state.parceiros}
          onChange={(e) => this.setState({ selectedParceiros: e.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione um ou mais parceiros"
          filter
        />
      </div>

      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Consulta de Parceria</h4>
          <div className="page-header-buttons">
            <Button
              label="Exportar em excel"
              onClick={() => this.handleExportarExcel()}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder='Procurar na consulta de parceria'
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            onFilter={this.customOnFilter}
            scrollDirection="horizontal"
            jaVemFilterAberto
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

export default ParceiroMovimentacao
