import { TabMenu } from 'primereact/tabmenu'
import React, { useEffect, useState } from 'react'
import PanelContainer from '../../../components/layout/PanelContainer'
import { showErrorMessage } from '../../../components/utils/Message'
import PessoaService from '../../../services/cadastro/PessoaService'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import AssinarAtpve from './AssinarAtpve'
import SolicitacaoDeEntrada from './SolicitacaoDeEntrada'

function EntradaVeiculoUsado (props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [veiculo, setVeiculo] = useState(null)
  const [proprietario, setProprietario] = useState(null)
  const [valorCompra, setValorCompra] = useState(0)

  function isEntradaVeiculoProprio () {
    return props.match.params.tipoEntrada === 'veiculo-proprio'
  }

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search)
    return params.get(param)
  }

  const fetchVeiculo = async (placa, codigoVei) => {
    try {
      const veiculoService = new VeiculoService()
      let qtdResultados = 0
      if (placa) {
        const veiculos = await veiculoService.filter(`apenasVeiculosUsados&descricaoFiltro=${placa}`, 1, 100)
        if (veiculos?.data?.length === 1) {
          setVeiculo(veiculos.data[0])
          return
        }
        qtdResultados = veiculos?.data?.length
      }
      if (codigoVei) {
        const veiculo = (await veiculoService.get(codigoVei))
        if (veiculo) {
          setVeiculo(veiculo)
          return
        }
      }
      // Se não parou antes, mostra a qtd para o usuário
      showErrorMessage(`Foram encontrados ${qtdResultados} resultados para o veículo ${placa || codigoVei}`)
    } catch (error) {
      console.error('Erro ao buscar veículos:', error)
    }
  }

  const fetchProprietario = async (cpfCnpjProprietario, codigoPes) => {
    try {
      const pessoaService = new PessoaService()
      let qtdResultados = 0
      if (cpfCnpjProprietario) {
        const proprietariosVei = await pessoaService.filter(`cpfCnpj=${cpfCnpjProprietario}`, 1, 100)
        if (proprietariosVei?.data?.length === 1) {
          setProprietario(proprietariosVei.data[0])
          return
        }
        qtdResultados = proprietariosVei?.data?.length
      }
      if (codigoPes) {
        const proprietarioVei = await pessoaService.get(codigoPes)
        if (proprietarioVei) {
          setProprietario(proprietarioVei)
          return
        }
      }
      // Se não parou antes, mostra a qtd para o usuário
      showErrorMessage(`Foram encontrados ${qtdResultados} resultados para o proprietário ${cpfCnpjProprietario || codigoPes}`)
    } catch (error) {
      console.error('Erro ao buscar proprietários:', error)
    }
  }

  useEffect(() => {
    const queryPlaca = getQueryParam('placa')
    if (queryPlaca) {
      fetchVeiculo(queryPlaca)
    }
    const queryVei = getQueryParam('codigo_veiculo')
    if (queryVei) {
      fetchVeiculo(null, queryVei)
    }
    const queryProprietario = getQueryParam('cpfcnpj_proprietario')
    if (queryProprietario) {
      fetchProprietario(queryProprietario)
    }
    const queryPes = getQueryParam('codigo_proprietario')
    if (queryPes) {
      fetchProprietario(null, queryPes)
    }
    const queryValorCompra = getQueryParam('valor')
    setValorCompra(queryValorCompra)
  }, [])

  function headerTemplate () {
    const model = [{ label: 'SOLICITAÇÃO DE ENTRADA' }]

    if (!isEntradaVeiculoProprio()) {
      model.push({ label: 'ATPV-E ASSINADO' })
    }
    // Só troca de página no botão Confirmar do SolicitacaoDeEntrada após preencher os dados
    return <TabMenu
      model={model}
      activeIndex={activeIndex}
      onTabChange={() => {}}
    />
  }

  return (
    <>
      <h4 className="mt-3 mb-0">
        {isEntradaVeiculoProprio() ? 'Entrada de veículo próprio' : 'Compra de veículo'}
      </h4>
      <PanelContainer toggeable={false} headerTemplate={headerTemplate()}>
        {activeIndex === 0 && (
          <SolicitacaoDeEntrada
            tipoEntrada={props.match.params.tipoEntrada}
            setActiveIndex={setActiveIndex}
            veiculo={veiculo}
            setVeiculo={setVeiculo}
            proprietarioVeiculo={proprietario}
            valorCompra={valorCompra}
          />
        )}
        {activeIndex === 1 && (
          <AssinarAtpve
            veiculo={veiculo}
          />
        )}
      </PanelContainer>
    </>
  )
}

export default EntradaVeiculoUsado
