import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import AccordionContainer from '../../components/layout/AccordionContainer'
import AccordionPage from '../../components/layout/AccordionPage'
import Container from '../../components/layout/Container'
import { baseForm } from '../../components/utils/BaseForm'
import Confirm from '../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../components/utils/Message'
import { getInfoFinanceiraDTO } from '../../dtos/suporte/InfoFinanceiraDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { setEmpresaLogada } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import EmpresaService from '../../services/cadastro/EmpresaService'
import DadosPrincInfoFinanceira from './DadosPrincInfoFinanceira'
import { InfoFinanceiraValidator } from './InfoFinanceiraValidator'

// * COMPONENTE FOOTER DO FORM
function ComponentFooter ({ edicaoHabilitada, form, setEdicaoHabilitada, defaultValues }) {
  return <div className="flex justify-content-end form-footer">
    {edicaoHabilitada && (
      <Button
        type="button"
        onClick={() => {
          Object.keys(defaultValues).forEach((key) => {
            form.setFieldValue(key, defaultValues[key])
          })
          setEdicaoHabilitada(false)
        }}
        label="Cancelar"
        className="p-button-danger mr-2"
      />
    )}
    {edicaoHabilitada && (
      <Button
        type="submit"
        label="Salvar"
        className="p-button"
      />
    )}
  </div>
}

// * COMPONENTE HEADER DO FORM
function ComponentHeader ({ title, onClickEdit }) {
  return <div className="flex justify-content-between form-header">
    <h1>{title}</h1>
    <div className="flex justify-content-between">
      <Button
        type="button"
        label="Editar"
        className="edit-button"
        icon="pi pi-pencil"
        onClick={onClickEdit}
      />
    </div>
  </div>
}

const InfoFinanceiraForm = ({ model }) => {
  // * ESTADOS de controle empresaValuesInicial (serve ao entrar para visualizar e após atualizar)
  const [empresaValues, setEmpresaValues] = useState(model)
  // * controle modal permitir editar
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const [senhaEdicao, setSenhaEdicao] = useState('')
  const [empFaturamentoSelecionada, setEmpFaturamentoSelecionada] = useState(model?.empFaturamentoEmp || null)
  const [parceiroSelecionado, setParceiroSelecionado] = useState(model?.parceiro || null)

  // * Verifica campos do form e manda o request se tudo tiver certo
  const handleSubmit = async (data) => {
    try {
      const empresaData = await EmpresaService.updateInformacoesFinanceiras({
        ...data,
        empfatEmp: empFaturamentoSelecionada?.codigo_emp,
        codpcrEmp: parceiroSelecionado?.codigo_pcr
      })
      const empresaDTO = getInfoFinanceiraDTO(empresaData)

      // * se estiver atualizando a empresa logada, atualiza ela no store do redux
      const empresaLogada = getStore().empresaLogada
      if (empresaLogada.codigo_emp === empresaData.codigo_emp) {
        const empresaAtualizada = {
          ...empresaLogada,
          ...empresaData
        }
        dispatch(setEmpresaLogada(empresaAtualizada))
      }

      // * Atualizar todos os campos no formulário
      Object.keys(empresaDTO).forEach((key) => {
        form.setFieldValue(key, empresaDTO[key])
      })
      setEmpresaValues(empresaDTO)
      setEmpFaturamentoSelecionada(empresaDTO.empFaturamentoEmp)
      setParceiroSelecionado(empresaDTO.parceiro)

      showSuccessMessage('Registro salvo com sucesso')
      setEdicaoHabilitada(false)
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro')
    }
  }

  // * baseForm para controlar os estados dos campos e o submit com seu validator
  const form = baseForm({
    initialValues: empresaValues,
    validationSchema: InfoFinanceiraValidator,
    onSubmit: handleSubmit
  })

  // * Usa esta função para validar os campos do formulário antes de fazer o submit do *form*
  async function validFieldsBeforeSubmit (e) {
    e.preventDefault()
    const errors = await form.validateForm(form.values)
    if (Object.keys(errors).length !== 0) {
      showWarnMessage('Campos obrigatórios não preenchidos!')
      return
    }
    form.handleSubmit()
  }

  // * valida senha para editar o form
  const testaSenhaDeAcessoEdicao = () => {
    if (senhaEdicao === 'infofin@renaveauto2025') {
      setEdicaoHabilitada(true)
      setVisibleConfirm(false)
      setSenhaEdicao('')
    } else {
      showErrorMessage('Senha incorreta, tente novamente!')
    }
  }

  return (
    <Container col="12">
      <form onSubmit={validFieldsBeforeSubmit}>
        <ComponentHeader
          title={`Informações Financeiras - Código Emp. ${form.values.codigoEmp}`}
          onClickEdit={
            () => !visibleConfirm && edicaoHabilitada
              ? setEdicaoHabilitada(false)
              : setVisibleConfirm(true)
          }
        />
        <div className="grid form-body">
          <AccordionContainer className={CommonHelper.isUsuarioDespachante() ? 'unique' : ''}>
            <AccordionPage header="Informações principais" active>
              <DadosPrincInfoFinanceira
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                empFaturamentoSelecionada={empFaturamentoSelecionada}
                setEmpFaturamentoSelecionada={setEmpFaturamentoSelecionada}
                parceiroSelecionado={parceiroSelecionado}
                setParceiroSelecionado={setParceiroSelecionado}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <ComponentFooter
          edicaoHabilitada={edicaoHabilitada}
          form={form}
          setEdicaoHabilitada={setEdicaoHabilitada}
          defaultValues={empresaValues}
        />
      </form>
      <Confirm
        visible={visibleConfirm}
        onConfirm={testaSenhaDeAcessoEdicao}
        onCancel={() => {
          setVisibleConfirm(false)
          setSenhaEdicao('')
        }}
        title="Confirmação"
        description = {
          <div className="field col-12">
            <p style={{ marginTop: '0px' }}>Deseja realmente editar este registro? Digite a senha para prosseguir!</p>
            <TextInput
              label="Digite a senha"
              placeholder="Digite a senha"
              name="senha_edicao"
              type='password'
              className="inputfield w-full"
              value={senhaEdicao}
              onChange={(e) => setSenhaEdicao(e.target.value)}
              disabled={!visibleConfirm}
            />
          </div>}
      />
    </Container>
  )
}

export default InfoFinanceiraForm
