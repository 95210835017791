import React from 'react'
import Modal from '../../../components/utils/Modal'
import { formatDate, formatToCurrency } from '../../../helpers/formaters'
import EntradaZeroKmService from '../../../services/zeroKm/veiculos/EntradaZeroKmService'

const TrocarVeiculoModal = ({ visible, onHide, veiculo, getAll }) => {
  const handleTrocarVeiculo = async (veiculo) => {
    await EntradaZeroKmService.selecionarNota(veiculo.codigo)
    onHide()
    getAll()
  }

  return (
    <Modal
      header={'Existem outras notas para este veículo'}
      visible={visible}
      onHide={onHide}
      width={60}
    >
      <h3 className="mb-5">Veículo: {veiculo?.chassi}</h3>
      <h3 className="mb-5">Selecione a nota para qual deseja trocar:</h3>
      {veiculo?.outrasNotas && veiculo.outrasNotas.map((outraNota, idx) =>
        <div
          key={outraNota.numeronf}
          className={'list-selection ' + (idx === 0 ? 'first' : '')}
          onClick={async () => {
            await handleTrocarVeiculo(outraNota)
          }}
        >
          <div>
            <p>
              <span>Número NF-e: {outraNota.numeronf}</span>
              <span> - Data emissão: {formatDate(outraNota.dataemissao)} - </span>
              <span>Valor Total: {formatToCurrency(outraNota.valorentrada)}</span>
            </p>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default TrocarVeiculoModal
