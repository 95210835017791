import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import CommonHelper from '../../../../helpers/CommonHelper'

// * Componente de CNPJ com MASK
function SancesInputCFPCNPJmask ({
  value = '',
  isFormFieldValid,
  onChange,
  onBlur,
  disabled,
  name,
  placeholder
}) {
  // * estado de controle da mascara e validação para ver se é cpf ou cnpj
  const [mask, setMask] = useState(null)
  const isEmpDesp = CommonHelper.isEmpresaDespachante()
  useEffect(async () => {
    if (!isEmpDesp) {
      setMask('99.999.999/9999-99')
    } else {
      let cpfCnj = value || ''
      if (cpfCnj.length === 14) {
        setMask('99.999.999/9999-99')
      } else {
        setMask('999.999.999-99')
      }
    }
  }, [])

  return (
    <ReactInputMask
      className={classNames({ 'p-invalid': isFormFieldValid }, 'p-inputtext p-component p-filled inputfield w-full')}
      mask={mask}
      maskChar="_"
      placeholder={placeholder || !isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

export default SancesInputCFPCNPJmask
