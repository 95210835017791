import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import React, { Component } from 'react'
import CommonHelper from '../../helpers/CommonHelper'

class DataList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: this.props.collapsed || true,
      expandedFilters: this.props.jaVemFilterAberto || false
    }
  }

  render () {
    const props = this.props
    const rows = props.data?.length
    const ExpandedFiltersTemplate = props.expandedFiltersTemplate

    return (
      <>
        {props.showFilter && (
          <div className="table-header grid formgrid">
            <div className="field col-12">
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" style={{ color: '#8a9099' }} />
                <InputText
                  placeholder={props.filterPlaceholder + ' (Pressione "Enter" para buscar)'}
                  className="inputfield w-full"
                  name={props.filterName}
                  onChange={props.filterOnChange}
                  disabled={props.loading}
                  value={props.filterValue}
                  onKeyDown={(e) => { if (e.key === 'Enter') props.onFilter() }} />
                {props.expandedFiltersTemplate && (
                  <i
                    className="pi pi-sliders-h cursor-pointer"
                    style={{ color: '#8a9099', right: '18px' }}
                    onClick={() => {
                      if (props.loading) return
                      this.setState({ expandedFilters: !this.state.expandedFilters })
                    }}
                  />
                )}
              </span>
            </div>
            {this.state.expandedFilters && (
              <ExpandedFiltersTemplate />
            )}
          </div>
        )}
        {(props.selected !== undefined || props.selectDatalist !== undefined) && (
          <DataTable
            className={'datalist p-datatable-responsive ' + props.className}
            resizableColumns
            header={props.actions}
            onSelectionChange={props.onSelectionChange || (event => props.onSelect(event.value))}
            onRowSelect={props.onRowSelect}
            onRowClick={props.onRowClick}
            paginatorPosition="bottom"
            responsive={props.responsive}
            selection={props.selected}
            selectionMode={props.selectionMode || 'single'}
            style={{ marginTop: '15px', minWidth: '300px', overflow: 'auto' }}
            value={props.data}
            loading={props.loading}
            expandedRows={props.expandedRows}
            rowExpansionTemplate={props.rowExpansionTemplate}
            dataKey={props.dataKey}
            emptyMessage="Nenhum registro foi encontrado"
            scrollable={props.scrollable}
            scrollDirection={props.scrollDirection}
            scrollHeight={props.scrollHeight}
            rowGroupMode={props.rowGroupMode}
            groupField={props.groupField}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            rowClassName={props.rowClassName}
            autoLayout={true}
            selectionPageOnly={props.selectionPageOnly}
          >
            {props.children}
          </DataTable>
        )}
        {(props.selected === undefined && props.selectDatalist === undefined) && (
          <DataTable
            className={'datalist p-datatable-responsive ' + props.className}
            resizableColumns
            header={props.actions}
            paginatorPosition="bottom"
            responsive={props.responsive}
            style={{ marginTop: '15px', minWidth: '300px', overflow: 'auto' }}
            value={props.data}
            expandedRows={props.expandedRows}
            rowExpansionTemplate={props.rowExpansionTemplate}
            dataKey={props.dataKey}
            emptyMessage="Nenhum registro foi encontrado"
            scrollable={props.scrollable}
            scrollDirection={props.scrollDirection}
            scrollHeight={props.scrollHeight}
            rowGroupMode={props.rowGroupMode}
            groupField={props.groupField}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            rowClassName={props.rowClassName}
          >
            {props.children}
          </DataTable>
        )}
        {props.page && (
          <div className="table-footer">
            <div className="table-rows">
              <span className="table-rows-quantity">
                Exibindo {props.totalRecords === 0 ? 0 : ((props.rows * (props.page - 1)) + 1)}-{props.rows * (props.page - 1) + rows} de {props.totalRecords}
              </span>
            </div>
            <Paginator
              className="paginator"
              style={{ marginTop: '10px' }}
              first={props.first}
              rows={props.rows}
              totalRecords={props.totalRecords}
              onPageChange={props.onPage}
              pageLinkSize={CommonHelper.isDesktop() ? 5 : 2}
            />
          </div>
        )}
      </>
    )
  }
}

export default DataList
